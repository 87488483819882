const moduleData =
    [
        {
            "moduleId": "3dfa43bb-c392-4373-a1cf-857e76b7a3d6",
            "moduleTitle": "Financial Statement Modeling",
            "childModules": [
                {
                    "moduleId": "4dd3b6cd-6bd2-4941-8232-1d25b70d5422",
                    "moduleTitle": "Income Statement Modeling",
                    "childProblems": [
                        {
                            "problemId": "68ba5d93-86e9-42b0-b582-4d136ab00400",
                            "problemTitle": "P&L Forecast 1",
                            "url": "https://quantus.finance/excel/module/4dd3b6cd-6bd2-4941-8232-1d25b70d5422/problem/68ba5d93-86e9-42b0-b582-4d136ab00400?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "0607226e-074a-4484-8ec4-5f03c13747e8",
                            "problemTitle": "P&L Forecast 2",
                            "url": "https://quantus.finance/excel/module/e247219f-01c4-4dc9-9e4c-ac06808e0017/problem/0607226e-074a-4484-8ec4-5f03c13747e8?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "13331e8b-6cd5-4768-a110-185b166d7728",
                            "problemTitle": "P&L Forecast - Apple",
                            "url": "https://quantus.finance/excel/module/4dd3b6cd-6bd2-4941-8232-1d25b70d5422/problem/13331e8b-6cd5-4768-a110-185b166d7728?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        }
                    ]
                },
                {
                    "moduleId": "e247219f-01c4-4dc9-9e4c-ac06808e0017",
                    "moduleTitle": "Balance Sheet Modeling",
                    "childProblems": [
                        {
                            "problemId": "91c771d9-7c8c-4687-881e-23a49b0f82dd",
                            "problemTitle": "Balance Sheet Forecast 1",
                            "url": "https://quantus.finance/excel/module/e247219f-01c4-4dc9-9e4c-ac06808e0017/problem/fd23e447-f097-4530-997e-55d45f1529d2?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "fd23e447-f097-4530-997e-55d45f1529d2",
                            "problemTitle": "Balance Sheet Forecast 2",
                            "url": "https://quantus.finance/excel/module/e247219f-01c4-4dc9-9e4c-ac06808e0017/problem/0607226e-074a-4484-8ec4-5f03c13747e8?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "a3326cbe-52cc-4952-b4ba-88d6fc5561ed",
                            "problemTitle": "Balance Sheet Forecast - Apple",
                            "url": "https://quantus.finance/excel/module/e247219f-01c4-4dc9-9e4c-ac06808e0017/problem/a3326cbe-52cc-4952-b4ba-88d6fc5561ed?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        }
                    ]
                },
                {
                    "moduleId": "aa6ae34c-959c-4d49-94c0-310a00445bad",
                    "moduleTitle": "Cashflow Statement Modeling",
                    "childProblems": [
                        {
                            "problemId": "55edca1b-d715-4173-8cda-b4e96a93535e",
                            "problemTitle": "Cash Flow Statement Forecast 1",
                            "url": "https://quantus.finance/excel/module/aa6ae34c-959c-4d49-94c0-310a00445bad/problem/55edca1b-d715-4173-8cda-b4e96a93535e?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "9347e1e3-0c14-4a9e-959c-7321f93f9860",
                            "problemTitle": "Cash Flow Statement Forecast 2",
                            "url": "https://quantus.finance/excel/module/aa6ae34c-959c-4d49-94c0-310a00445bad/problem/9347e1e3-0c14-4a9e-959c-7321f93f9860?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "be9052ec-b8a6-4a5c-b61b-7ae21f928761",
                            "problemTitle": "Cash Flow Statement Forecast - Apple",
                            "url": "https://quantus.finance/excel/module/aa6ae34c-959c-4d49-94c0-310a00445bad/problem/be9052ec-b8a6-4a5c-b61b-7ae21f928761?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        }
                    ]
                },{
                    "moduleId": "61ba6ced-8432-42dd-95a7-9ef536cc5f65",
                    "moduleTitle": "3-Statement Modeling",
                    "childProblems": [
                        {
                            "problemId": "a908371d-40e8-4645-a7e0-7366462d853b",
                            "problemTitle": "Financial Statement Modeling - Apple",
                            "url": "https://quantus.finance/excel/module/61ba6ced-8432-42dd-95a7-9ef536cc5f65/problem/a908371d-40e8-4645-a7e0-7366462d853b?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        } 
                    ]
                }
            ]

        },
        {
            "moduleId": "9dffd05c-1f6a-4131-b965-e1bd7fe5ce25",
            "moduleTitle": "DCF Modeling",
            "childModules": [
                {
                    "moduleId": "33717efb-b772-43aa-8cd0-68014bbcbda1",
                    "moduleTitle": "Discounted Cash Flow Modeling",
                    "childProblems": [
                        {
                            "problemId": "3b70ba9e-3fc8-47a8-ab9c-e9e21bac9200",
                            "problemTitle": "Discounted Cash Flow Building 1",
                            "url": "https://quantus.finance/excel/module/33717efb-b772-43aa-8cd0-68014bbcbda1/problem/3b70ba9e-3fc8-47a8-ab9c-e9e21bac9200?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "df04f497-fa7e-4a24-b7b5-9e5e3a5c3de2",
                            "problemTitle": "Discounted Cash Flow Building - WebMD",
                            "url": "https://quantus.finance/excel/module/33717efb-b772-43aa-8cd0-68014bbcbda1/problem/df04f497-fa7e-4a24-b7b5-9e5e3a5c3de2?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "66cdc60a-db82-43db-b4a2-c12d9451cb7c",
                            "problemTitle": "Discounted Cash Flow Building - Apple",
                            "url": "https://quantus.finance/excel/module/33717efb-b772-43aa-8cd0-68014bbcbda1/problem/66cdc60a-db82-43db-b4a2-c12d9451cb7c?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        }
                    ]
                },
                {
                    "moduleId": "4a4f0871-0b48-499a-8283-d0ffdbdac391",
                    "moduleTitle": "CashFlowsLab DCF",
                    "childProblems": [
                        {
                            "problemId": "51b3cc6b-7a56-475b-b598-64a74f4f0937",
                            "problemTitle": "DCF Recruiting Model Unsolved",
                            "url": "https://cashflowslab-excel-downloadables.s3.eu-central-1.amazonaws.com/CashFlowsLab_DCF_Recruiting_Model_basic.xlsx"
                        },
                        {
                            "problemId": "5d4788f1-04be-4746-aab8-e99037f77eae",
                            "problemTitle": "DCF Recruiting Model Solved",
                            "url": "https://cashflowslab-excel-downloadables.s3.eu-central-1.amazonaws.com/CashFlowsLab_DCF_Recruiting_Model_Solved.xlsx"
                        }
                    ]
                }
        
            ]
        },
        {
            "moduleId": "db86d3fc-0776-4128-b9d9-74dc1f6a6c46",
            "moduleTitle": "LBO Modeling",
            "childModules": [
                {
                    "moduleId": "666ad41c-1a1e-45a2-a8ad-e01c4a3d2ee6",
                    "moduleTitle": "Build Leveraged Buyout Model",
                    "childProblems": [
                        {
                            "problemId": "4816969b-4cf3-4f6c-a0b0-41ee97a31895",
                            "problemTitle": "Build Leveraged Buyout Model 1",
                            "url": "https://quantus.finance/excel/module/666ad41c-1a1e-45a2-a8ad-e01c4a3d2ee6/problem/4816969b-4cf3-4f6c-a0b0-41ee97a31895?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "9310256f-314f-4cd8-948e-e8fcdbc14e58",
                            "problemTitle": "Build Leveraged Buyout Model - Dell",
                            "url": "https://quantus.finance/excel/module/666ad41c-1a1e-45a2-a8ad-e01c4a3d2ee6/problem/9310256f-314f-4cd8-948e-e8fcdbc14e58?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                        {
                            "problemId": "9a3ae932-31d4-4c10-bee6-1d32ad63d6db",
                            "problemTitle": "Build Leveraged Buyout Model - JCG",
                            "url": "https://quantus.finance/excel/module/666ad41c-1a1e-45a2-a8ad-e01c4a3d2ee6/problem/9a3ae932-31d4-4c10-bee6-1d32ad63d6db?utm_source=financialmodelinginfo&utm_medium=financialmodelinginfo&utm_name=financialmodelinginfo&utm_term=1&utm_content=link"
                        },
                    ]
                }

            ]
        }
    ]

export default moduleData;